import React, { useEffect, useState } from "react";
import styles from "./studentDetailsAssignments.module.scss";
import SearchBar from "../../../../../common/searchBar/searchBar";
import FilterBar from "../../../../../common/filterBar/filterBar";
import { CustomPagination } from "../../../../../common/pagination/customPagination";
import StudentAssignmentCardItem from "./components/studentAssignmentCardItem/studentAssignmentCardItem";
import { constants } from "../../../../../constants/constants";
import Dropdown from "../../../../../common/dropdown/dropdown";
import { routePaths } from "../../../../../constants/routes";
import { formatDate } from "../../../../../utils/dateFormatter";
import { calculateTimeTaken } from "../../../../../utils/convertTime";
import useSorting from "../../../../../utils/useSorting";
import StatusContext from "../../../../../context/loaderContext";
import { useParams } from "react-router-dom";
import useSortingByDate from "../../../../../utils/useSortingByDate";
import capitalizeAfterSpace from "../../../../../utils/capitalizeFirstLetter";

const StudentDetailsAssignments = ({ studentsAssignmentsData }: any) => {
  const [searchText, setSearchText] = useState("");
  const [chapterDropDownValueWithId, setChapterDropDownValueWithId] =
    useState<any>({});
  const [assignmentDropDownList, setAssignmentDropDownList] = useState<any>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [patternOptions, setPatternOptions] = useState<any[]>([]);
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const params = useParams();
  const path = `${routePaths.descriptiveQuestionReviewPage.replace(
    ":classId",
    params.classId ?? ""
  )}/?studentId=${params.studentId}`;

  const dataMassaging = (data: any) => {
    const arr = [{ name: "All Exercises", id: 0 }];
    const uniqueNames = new Set(["All Assignments"]);

    return data?.map((item: any) => {
      const name =
        item?.assignment_index && capitalizeAfterSpace(item?.assignment_index);
      if (name && !uniqueNames.has(name)) {
        uniqueNames.add(name);
        arr.push({
          name: name,
          id: item?.topic_id,
        });
      }
      setAssignmentDropDownList(arr);
      return {
        timeTaken: calculateTimeTaken(item?.time_taken),
        submittedOn: formatDate(item?.submitted_on),
        status: item?.status,
        assignment_index: item?.assignment_index,
        assignmentName: item?.assignment_name,
        questionName: item?.question,
        questionId: item?.topic_id,
        question_id: item?.question_id,
        topicId: item?.topic_id,
        topicSlNo: item?.topic_slno,
      };
    });
  };

  const {
    dropdownSelectedValueWithId,
    dropdownQuestionsList,
    selectedValues,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    handlePaginationChange,
    range,
    detailedViewRef,
  } = useSorting(
    studentsAssignmentsData?.submission_list,
    selectedStatus,
    searchText,
    "questionName",
    dataMassaging,
    10,
    dropdownArray
  );
  useEffect(() => {
    if (dropdownSelectedValueWithId) {
      if (
        dropdownSelectedValueWithId?.defaultLabel?.trim() === "All Assignments"
      ) {
        setChapterDropDownValueWithId(dropdownSelectedValueWithId);
      }
    }
  }, [dropdownSelectedValueWithId]);
  useEffect(() => {
    let arr: any = [];
    if (assignmentDropDownList) {
      setPatternOptions(
        assignmentDropDownList && assignmentDropDownList.length
          ? [{ data: assignmentDropDownList, defaultLabel: "All Assignments" }]
          : []
      );
    }
    if (patternOptions && patternOptions.length > 0) {
      patternOptions.map((x: any) => {
        if (
          x?.defaultLabel?.trim() ===
          chapterDropDownValueWithId?.defaultLabel?.trim()
        ) {
          arr.push({ ...x, chapterDropDownValueWithId });
        }
      });
    }
    setDropdownArray(arr);
  }, [chapterDropDownValueWithId, dropdownQuestionsList]);

  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);
  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  return (
    <div
      className={styles.studentDetailsAssignmentsMainContainer}
      ref={detailedViewRef}
    >
      <div className={styles.assignmnetTitleSearchbarContainer}>
        <div className={styles.assignmnetTitleText}>
          {constants.CLASS_ASSIGNMENT_TITLE.replace(
            "students for review",
            studentsAssignmentsData?.student_name
          )}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>
      <div className={styles.separator}></div>
      <div className={styles.assignmnetsListingFilterWrap}>
        <div className={styles.statusAndChapterFilter}>
          {/* <div className={styles.studentsAssignmnetsFilterWrap}>
            <FilterBar
              hideFilter={true}
              statuses={["All", "Pending", "Completed"]}
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses.map((x) => x.toLowerCase()))
              }
              showSorting={false}
            />
          </div> */}
          <div>
            {patternOptions && patternOptions.length > 0 && (
              <div className={styles.chpatternsFilterContainer}>
                <Dropdown
                  options={patternOptions ? patternOptions[0]?.data : []}
                  defaultLabel={"All Assignments "}
                  minWidth={"255px"}
                  selectedValue={(item: string) => console.log("")}
                  setDropdownSelectedValueWithId={
                    setDropdownSelectedValueWithId
                  }
                />
              </div>
            )}
          </div>
        </div>
        <div>
          <FilterBar
            showSorting={true}
            sortFunc={(sortType: string) => handleSorting(sortType)}
          />
        </div>
      </div>
      <div className={styles.sortedAssignmentContainer}>
        {sortedByDateArr
          ?.slice(paginationStart, paginationEnd)
          ?.map((item: any, index: number) => (
            <>
              <StudentAssignmentCardItem
                item={item}
                key={`${item.assignmentName}_${index}`}
                routePath={path}
                index={index}
              />
              <div className={styles.seperator}></div>
            </>
          ))}
      </div>
      {sortedByDateArr.length === 0 && (
        <div className={styles.noDataFound}>No Data Found</div>
      )}
      {sortedByDateArr?.length > 0 && (
        <CustomPagination
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(sortedByDateArr?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(StudentDetailsAssignments);

import React, { useEffect, useState } from 'react'

import QuestionInputText from './questionTextInput/questionTextInput'
import QuestionFileInput from './questionFileInput/questionFileInput'
import QuestionUploadedFiles from "./questionUploadedFiles/questionUploadedFiles"
import styles from "./descriptiveQuestion.module.scss";
import { useDispatch } from 'react-redux';


function moveArrayElement(array: any, fromIndex: number, toIndex: null) {
  let numberOfDeletedElm = 1;
  const elm = array.splice(fromIndex, numberOfDeletedElm)[0];
  numberOfDeletedElm = 0;
  array.splice(toIndex, numberOfDeletedElm, elm);
}

const DescriptiveQuestion = (props: any) => {
  const { activeQuestionIndex, question, setQuestion, setOptionClicked, userType } = props;
  const [inputText, setInputText] = useState(userType == 'mentor' ?
    question[activeQuestionIndex]?.mentor_comments || "" : question[activeQuestionIndex]?.student_comment)
  const [uploadedFiles, setUploadedFiles] = useState<any>(question[activeQuestionIndex]?.uploadedImages || [])
  const [dragId, setDragId] = useState();
  const dispatch = useDispatch()
  useEffect(() => {
    setUploadedFiles(question[activeQuestionIndex]?.uploadedImages || [])
    setInputText(userType == 'mentor' ?
      question[activeQuestionIndex]?.mentor_comments || "" : question[activeQuestionIndex]?.student_comment)
  }, [activeQuestionIndex])

  const onChangeInputText = (event: any) => {
    setInputText(event.target.value);
    setOptionClicked && setOptionClicked(true)

  }
  useEffect(() => {
    setQuestion(
      () => {
        return question?.map((item1: any) => {
          if (item1.id === question[activeQuestionIndex]?.id) {
            //item1.student_comment = inputText
           // item1.mentor_comment = inputText
            userType == 'mentor' ?
              item1.mentor_comments = inputText : item1.student_comment = inputText
          }
          return item1
        })
      }
    )
    dispatch({ type: "UPDATE_QUESTION", payload: question })
  }, [inputText])
  useEffect(() => {
    setQuestion(
      () => {
        return question?.map((item1: any) => {
          if (item1.id === question[activeQuestionIndex]?.id) {
            item1.uploadedImages = uploadedFiles
          }
          return item1
        })
      }
    )
  }, [uploadedFiles])

  const onUpdateInputFiles = (file: any) => {  
    setUploadedFiles((prev: any) => [...prev, ...file])
  }
  const onDeleteFile = (indexToRemove: number) => {
    const uploadedFilesAfterDeletion = uploadedFiles.filter((data: any, index: number) => index !== indexToRemove);
    setUploadedFiles(uploadedFilesAfterDeletion)
  }
  const handleDrag = (ev: any) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev: any) => {
    const dragBoxIndex = uploadedFiles.findIndex((file: any) => file.id === dragId);
    const dropBoxIndex = uploadedFiles.findIndex((file: any) => file.id === ev.currentTarget.id);
    const uploadedFilesCopy = [...uploadedFiles]
    moveArrayElement(uploadedFilesCopy, dragBoxIndex, dropBoxIndex)
    setUploadedFiles(uploadedFilesCopy);
  };

  const uploadedFilesCount = uploadedFiles.length;


  return (<div className={styles.descriptiveQuestionContainer}>
    <QuestionInputText inputText={inputText} onChangeInputText={onChangeInputText} />
    <QuestionFileInput
      onUpdateInputFiles={onUpdateInputFiles}
      uploadedFilesCount={uploadedFilesCount}
    />
    <QuestionUploadedFiles
      uploadedFiles={uploadedFiles}
      setOptionClicked={setOptionClicked}
      onDeleteFile={onDeleteFile}
      handleDrag={handleDrag}
      handleDrop={handleDrop}
    />
  </div>
  )
}

export default DescriptiveQuestion
import React from "react";
import styles from "./dashboardPage.module.scss";
import TestCard from "../../common/cards/testCard";
import HomeworkCard from "../../common/cards/homeworkCard";
import PracticeTestChapterCard from "../../common/cards/practiceTestChapterCard";
import ExerciseCard from "../../common/cards/exerciseCard";

const UIPreview = () => {
  return (
    <div className={styles.UIPreviewContainer}>
      {/* <div className={styles.componentPreview}>
        <h2>Test Cards</h2>
        <div className={styles.oneColumnGrid}>
          <TestCard status="Pending" data={null} />
          <TestCard status="Review" data={null} />
          <TestCard status="Skipped" data={null} />
          <TestCard status="Completed" data={null} />
        </div>
      </div>
      <div className={styles.componentPreview}>
        <h2>Homework Cards</h2>
        <div className={styles.twoColumnGrid}>
          <HomeworkCard status="Pending" data={null} />
          <HomeworkCard status="Completed" data={null} />
          <HomeworkCard status="Incomplete" data={null} />
          <HomeworkCard status="Pending" data={null} />
          <HomeworkCard status="Completed" data={null} />
          <HomeworkCard status="Incomplete" data={null} />
        </div>
      </div> */}
      {/* <div className={styles.componentPreview}>
        <h2>Exercise cards</h2>
        <div className={styles.oneColumnGrid}>
          <ExerciseCard />
          <ExerciseCard />
        </div>
      </div> */}
      <div className={styles.componentPreview}>
        <h2>Practice Test Small cards</h2>
        <div className={styles.threeColumnGrid}>
          <PracticeTestChapterCard status="pending" data={null} />
        </div>
      </div>
    </div>
  );
};

export default UIPreview;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import styles from "./myAssignments.module.scss";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { constants } from "../../constants/constants";
import StatusContext from "../../context/loaderContext";
import ApiEdpoints, { HTTPMethods } from "../../constants/api_endpoints";
import TestList from "./testList";

const TestPage = ({ istabbedPage = false }: any) => {
  const detailedViewRef = useRef(null);
  const [assignmentsData, setAssignmentsData] = useState<object[]>([]);
  const loaderStatus: any = useContext(StatusContext);
  const courseId = useSelector(
    (state: any) => state.userReducer.registeredCourseId
  );
  const studentSection = useSelector(
    (state: any) => state.userReducer.studentSection
  );
  const fetchAssignmentsList = async () => {
    loaderStatus.setState({ show: true });
    const payload = {
      url: `${ApiEdpoints.GENERATETESTPAPER}/?course_id=${courseId}&section=${studentSection}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);
      if (response && response.status === 200) {
        const assignmentsDataRes = response?.data?.data?.test_paper_data;
        setAssignmentsData(assignmentsDataRes);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignmentsList();
  }, []);

  return (
    <div
      className={`${styles.myAssignmentsMainContainer} ${
        istabbedPage ? styles.allAssignmentsMainContainerTabbed : ""
      }`}
      ref={detailedViewRef}
    >
      {!istabbedPage && (
        <Breadcrumb
          breadcrumbDetails={[
            {
              label: "Home",
              // link: "/home",
              selected: false,
            },
            {
              label: "My Tests",
              link: "/tests",
              selected: true,
            },
          ]}
        />
      )}

      <TestList
        detailedViewRef={detailedViewRef}
        assignmentsData={assignmentsData}
        istabbedPage={istabbedPage}
      />
    </div>
  );
};

export default React.memo(TestPage);

import sideNavHomeIcon from "../assets/images/sideNavHomeIcon.svg";
import sideNavLearnIcon from "../assets/images/sideNavLearnIcon.svg";
import sideNavLogoutIcon from "../assets/images/sideNavLogoutIcon.svg";
import sideNavMyWorkIcon from "../assets/images/sideNavMyWorkIcon.svg";
import sideNavActivePraticeIcon from "../assets/images/Pratice_active.svg";
import sideNavInActivePraticeIcon from "../assets/images/Pratice_inacticve.svg";
import sideNavActiveexerciseIcon from "../assets/images/exercise_active.svg";
import sideNavInActiveexerciseIcon from "../assets/images/exercise_inactive.svg";
import sideNavActiveHomeWorkIcon from "../assets/images/Homework_inactive.svg";
import sideNavInActiveHomeWorkIcon from "../assets/images/homework_active.svg";
import sideNavActiveHomeIcon from "../assets/images/sideNavActiveHomeIcon.svg";
import sideNavActiveLearnIcon from "../assets/images/sideNavActiveLearnIcon1.svg";
import sideNavActiveLogoutIcon from "../assets/images/sideNavActiveLogoutIcon.svg";
import sideNavActiveMyWorkIcon from "../assets/images/sideNavActiveMyWorkIcon.svg";
import assignmentActive from "../assets/images/assignmentActive.svg";
import assignmentInactive from "../assets/images/assignmentInactive.svg";
import sideNavMyClassIcon from "../assets/images/myclass.svg";
import sideNavMyClassActiveIcon from "../assets/images/myclassActive.svg";
import testIcon from "../assets/images/test.svg";
import homeWorkIcon from "../assets/images/homework.svg";
import queriesIcon from "../assets/images/queries.svg";
import sideNavStatIconActive from "../assets/images/myStatsICON.svg";
import sideNavStatIconInActive from "../assets/images/myStatsInActiveImg.svg";
import sideNavTestInActive from "../assets/images/Test_student.svg";
import sideNavTestActive from "../assets/images/Test_Student_Active.svg";
import sideNavNotificationIconInActive from "../assets/images/Notification_student.svg";
import sideNavNotificationIconActive from "../assets/images/Notification_student_active.svg";


export const constants = {
  ASSIGNMENT_ATTEMPTED: "assignments attempted",
  ADDUSER: "ADDUSER",
  NOTIFICATION: "NOTIFICATION",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  LOGGEDIN: "LoggedIn",
  USERTYPE: "USERTYPE",
  GREETINGS: "Hello user, here all the chapters planned for you",
  MENTOR_GREETINGS: "Hello user, here are all your assigned classes",
  MENTOR_GREETINGS_CD: "Hello user, here is a quick overview of your class",
  COPYRIGHT: "Copyright 2023 AI Educator. All rights reserved.",
  TERMSCONDITIONS: "Terms & Conditions",
  PRIVACYPOLICY: "Privacy Policy",
  DESCRIPTION: "Description",
  FAQ: "FAQ",
  YOUR_SCORE: "Your Score",
  SCORE: "Score",
  ATTEMPTED_DATE: "Attempt Date",
  TIME_TAKEN: "Time Taken",
  MARKS_SCORED_BY_STUDENT: "Marks Scored by Student",
  SELECTED_VIDEO_TOPIC_CONTENT: "SELECTED_VIDEO_TOPIC_CONTENT",
  SIDE_NAV_DATA_TEACHERS: [
    {
      title: "My Class",
      image: sideNavMyClassIcon,
      activeImage: sideNavMyClassActiveIcon,
      count: 0,
      path: "/mentor/my-class",
      isChecked: false,
    },
    // {
    //   title: "My Work",
    //   image: sideNavMyWorkIcon,
    //   activeImage: sideNavActiveMyWorkIcon,
    //   count: 0,
    //   path: "/mentor/my-work",
    //   isChecked: false,
    // },
    // {
    //   title: "Notifications",
    //   image: sideNavNotificationIcon,
    //   activeImage: sideNavActiveNotificationIcon,
    //   count: 0,
    //   path: "/notifications",
    //   isChecked: false,
    // },
    // {
    //   title: "Help",
    //   image: sideNavHelpIcon,
    //   activeImage: sideNavActiveHelpIcon,
    //   count: 0,
    //   path: "/help",
    //   isChecked: false,
    // },
    {
      title: "Logout",
      image: sideNavLogoutIcon,
      activeImage: sideNavActiveLogoutIcon,
      count: 0,
      path: "logout",
      isChecked: false,
    },
  ],
  SIDE_NAV_DATA: [
    // {
    //   title: "Home",
    //   image: sideNavHomeIcon,
    //   activeImage: sideNavActiveHomeIcon,
    //   count: 0,
    //   path: "home",
    //   isChecked: false,
    // },
    {
      title: "Learn",
      image: sideNavLearnIcon,
      activeImage: sideNavActiveLearnIcon,
      count: 0,
      path: "learn",
      isChecked: false,
    },
    {
      title: "Practice",
      image: sideNavInActivePraticeIcon,
      activeImage: sideNavActivePraticeIcon,
      count: 0,
      path: "practice/homeworks",
      isChecked: false,
      children:[
        {
          title: "Homework",
          image: sideNavInActiveHomeWorkIcon,
          activeImage:sideNavActiveHomeWorkIcon,
          count: 0,
          path: "practice/homeworks",
          isChecked: false,
          
        },
        // {
        //   title: "Practice Test",
        //   image: sideNavLearnIcon,
        //   activeImage: sideNavActiveLearnIcon,
        //   count: 0,
        //   path: "practice/practiceTest",
        //   isChecked: false,
          
        // },
        {
          title: "Exercises",
          image: sideNavInActiveexerciseIcon,
          activeImage: sideNavActiveexerciseIcon,
          count: 0,
          path: "practice/exercises",
          isChecked: false,
          
        },
      ]
    },
    // {
    //   title: "My Work",
    //   image: sideNavMyWorkIcon,
    //   activeImage: sideNavActiveMyWorkIcon,
    //   count: 4,
    //   path: "my-work",
    //   isChecked: false,
    // },
    {
      title: "Performance",
      image: sideNavStatIconInActive,
      activeImage: sideNavStatIconActive,
      count: 0,
      path: "my-stats",
      isChecked: false,
    },
    {
      title: "Tests",
      image: sideNavTestInActive,
      activeImage: sideNavTestActive,
      count: 0,
      path: "tests",
      isChecked: false,
    },
    {
      title: "Notifications",
      image: sideNavNotificationIconInActive,
      activeImage: sideNavNotificationIconActive,
      count: 0,
      path: "/notifications",
      isChecked: false,
    },
    // {
    //   title: "My Assignments",
    //   image: assignmentInactive,
    //   activeImage: assignmentActive,
    //   count: 0,
    //   path: "assignments",
    //   isChecked: false,
    // },
    // {
    //   title: "Notification",
    //   image: sideNavNotificationIcon,
    //   activeImage: sideNavActiveNotificationIcon,
    //   count: 6,
    //   path: "notification",
    //   isChecked: false,
    // },
    // {
    //   title: "Help",
    //   image: sideNavHelpIcon,
    //   activeImage: sideNavActiveHelpIcon,
    //   count: 0,
    //   path: "help",
    //   isChecked: false,
    // },
    {
      title: "Logout",
      image: sideNavLogoutIcon,
      activeImage: sideNavActiveLogoutIcon,
      count: 0,
      path: "logout",
      isChecked: false,
    },
  ],
  LEARN_BREADCRUMB_DATA: [
    {
      label: "Home",
      // link: "/home",
      selected: false,
    },
    {
      label: "Learn",
      link: "/learn",
      selected: true,
    },
  ],
  LEARN_BREADCRUMB_DATA_MENTOR: [
    {
      label: "Mentor",
      link: "/mentor/my-class",
      selected: false,
    },
    {
      label: "My Class",
      link: "/mentor/my-class",
      selected: true,
    },
  ],
  TOKEN: "token",

  // validation messages.
  required: "* Required",
  CHAPTER_DETAILS_BREADCRUMB_DATA: [
    {
      label: "Home",
      // link: "/dashboard",
      selected: false,
    },
    {
      label: "Learn",
      link: "/learn",
      selected: false,
    },
    {
      label: "Chapter 01",
      link: "/learn/chapterDetails/23",
      selected: true,
    },
  ],
  // TAB NAMES
  OVERVIEW: "Overview",
  TOPIC: "Topic",
  TOPICS: "Topics",
  ASSESSMENTS: "Exercises",
  EXERCISES: "Exercises",
  EXERCISE: "Exercise",
  TESTS: "Tests",
  HOMEWORK: "Homework",
  PATTERN: "Pattern",
  SAMPLE_QUESTIONS: "Sample Questions",
  QA: "Q&A",
  // CHAPTER DETAILS SCREEN CONSTANTS
  COMPLETED_ASSIGNMENTS: "Completed Exercises",
  COMPLETED_TOPICS: "Completed Topics",
  SUGGESTION_FOR_YOU: "Suggestion for you",
  KNOW_MORE: "Know More",
  TESTS_FOR_YOU: "Tests for you",
  HERE_ARE_THINGS_THAT_YOU_NEED_TO_SOLVE_TODAY:
    "Here are things that you need to solve today",
  VIEW_ALL_TESTS: "View all Tests",
  COMPLETE_AND_SUBMIT_THE_PENDING_WORK:
    "Complete and submit the pending homework",
  VIEW_ALL_HOMEWORK: "View all homework",
  QUESTIONS: "Questions",
  MARKS: "Marks",
  MINUTES: "Minutes",
  PATTERNS: "Concepts",
  SOLVE_NOW_CHAPTER_LISTING: "SOLVE NOW",
  VIEW_ALL_PATTERNS: "View all patterns",
  VIEW_ALL_TOPICS: "View all topics",
  TOPICS_TO_MASTER: "Start practising these topics to master.",
  MY_ANALYTICS: "My Analytics",
  VIEW_ANALYTICS: "View Analytics",
  SUBMISSIONS: "Submissions",
  // PATTERNLISTING DUMMY DATA
  DUMMY_PATTERNDATA: [
    {
      patternName: "1098  |  Commutative",
      patternDescription:
        "Under subtraction, addition, division & multiplication.",
      patternStrength: "Weak",
      level: "Primary",
      background:
        "linear-gradient(304.25deg, #5A66C8 12.81%, #896AF3 109.93%);",
    },
    {
      patternName: "1098  |  Commutative + 1094 Additive",
      patternDescription:
        "Under subtraction, addition, division & multiplication.",
      patternStrength: "Strong",
      level: "Primary",
      background:
        "linear-gradient(304.25deg, #5A66C8 12.81%, #896AF3 109.93%);",
    },
    {
      patternName: "1095  |  Subtraction | 1094 Additive",
      patternDescription:
        "Under subtraction, addition, division & multiplication.",
      patternStrength: "Weak",
      background: "linear-gradient(4.53deg, #9E5BB8 5.46%, #DC95F5 105.81%)",
      level: "Secondary",
    },
    {
      patternName: "1098  |  Commutative + 1094 Additive",
      patternDescription:
        "Under subtraction, addition, division & multiplication.",
      patternStrength: "Strong",
      level: "Primary",
      background:
        "linear-gradient(304.25deg, #5A66C8 12.81%, #896AF3 109.93%);",
    },
    {
      patternName: "1095  |  Subtraction | 1094 Additive",
      patternDescription:
        "Under subtraction, addition, division & multiplication.",
      patternStrength: "Weak",
      background: "linear-gradient(4.53deg, #9E5BB8 5.46%, #DC95F5 105.81%)",
      level: "Secondary",
    },
    {
      patternName: "1098  |  Commutative",
      patternDescription:
        "Under subtraction, addition, division & multiplication.",
      patternStrength: "Weak",
      level: "Tertiary",
      background: "linear-gradient(180deg, #9DE4D8 -45.65%, #3F86B4 93.48%)",
    },
  ],
  // TOPIC_DETAILS DUMMY DATA
  DUMMY_TOPIC_DETAILS: [
    {
      title: "Introduction to numbers",
      suggestion:
        "Use 'Origin Number' pattern to solve the pending assessment and increase your chapter mastery by 15%",
      video: "01",
      ppt: "",
      article: "",
    },
    {
      title: "Knowing our numbers and key theorems",
      suggestion: "01",
      video: "01",
      ppt: "03",
      article: "01",
    },
    {
      title: "Knowing our numbers and key theorems",
      suggestion: "01",
      video: "01",
      ppt: "02",
      article: "06",
    },
    {
      title: "Estimations",
      video: "01",
      ppt: "01",
      article: "01",
    },
    {
      title: "Properties of Whole Numbers",
      suggestion:
        "Use 'Origin Number' pattern to solve the pending assessment and increase your chapter mastery by 15%",
      video: "01",
      ppt: "02",
      article: "04",
    },
  ],
  // OVERLAY CONSTANTS
  NOTESTITLE: "Notes |",
  VIEWALL: "View all",
  ADD_NEW_NOTES: "Add new notes",
  PLAY_VIDEO_CLIP: "Play video clip",
  ADD_YOUR_NOTE: "Add Your Note",
  WRITE_HERE: "Write here.",
  CANCEL: "Cancel",
  SAVE: "Save",
  EDIT_YOUR_NOTE: "Edit your note",
  DELETE: "Delete",

  WATCH_VIDEO: "Watch Video",
  GET_STARTED: "Get Started",
  YOUR_EXAM_READY: "You are Exam Ready",
  CHAPTER_DETAILS_ONBOARDING_TEXT:
    "Hey! great to have you here, let’s get you started. Click below to answer few questions that will help us curate the content effectively.",

  QA_ITEM: {
    list: [
      {
        author: "You",
        time: "24 Dec 2022, 6:00 pm",
        questionTitle:
          "Can you please explain this closure properties for the question below?",
        upvotes: "20",
        downvotes: "20",
        replies: [
          {
            author: "Facul",
            time: "24 Dec 2022, 6:00 pm",
            replies: [],
            questionTitle:
              "@Hemanth The problem can be solved by using Rational Number Concept of closure. You first need to divide the equation in 2 parts and then combine them in the final step",
          },
          {
            author: "You",
            time: "24 Dec 2022, 6:00 pm",
            questionTitle:
              "Can you please explain this closure properties for the question below?",
            replies: [
              {
                author: "Facul",
                time: "24 Dec 2022, 6:00 pm",
                questionTitle:
                  "@Hemanth The problem can be solved by using Rational Number Concept of closure. You first need to divide the equation in 2 parts and then combine them in the final step",
              },
              {
                author: "You",
                time: "24 Dec 2022, 6:00 pm",
                questionTitle:
                  "Can you please explain this closure properties for the question below?",
              },
            ],
          },
        ],
      },
    ],
  },
  //CHAPTER LISTING CONTANTS
  GET_STARTED_CHAPTER_LISTING: "GET STARTED",
  ASSIGNMENTS: "Exercises",
  CHAPTER: "Chapter",
  KNOW_HOW: "Know How",
  SOLVE_NOW: "Solve Now",
  VIEW_TOPICS: "View Topics",
  NEW_HOMEWORK_BY_FACULTY: "New homework sent by faculty.",
  PPT: "PPT",
  MCQ: "MCQ",
  VIEW_ALL: "View All",
  VIEW: "View",
  RETAKE: "Retake",
  // CHAPTERDETAILS HOMEWORK CONSTANTS
  HOMEWORK_TITLE: "Here is all the homework assigned to for this chapter",
  LOOKING_FOR_SOMETHING: "Looking for something?",
  SUGGESTION_TEXT: "You have done better than 40% of your class.",
  VIEW_RESULT: "View Result",
  HOMEWORK_LIST_DUMMYDATA: [
    {
      name: "polynomis basics",
      status: "Pending",
      duration: 30,
      questions: 20,
      marks: 25,
      date: "Submit by 07 Jan ‘23",
      homeWorkStatus: "Start",
    },
    {
      name: "basics",
      status: "Pending",
      duration: 30,
      questions: 20,
      marks: 25,
      date: "Overdue by 15 days",
      homeWorkStatus: "Continue",
    },
    {
      name: "real numbers",
      status: "In Review",
      duration: 30,
      questions: 20,
      marks: 25,
      date: "Submit by 07 Jan ‘23",
      homeWorkStatus: "View",
    },
    {
      name: "whole numbers",
      status: "Completed",
      duration: 30,
      questions: 20,
      marks: 25,
      date: "Submit by 07 Jan ‘23",
      homeWorkStatus: "Retake",
      suggestion: true,
    },
    {
      name: "equations",
      status: "Completed",
      duration: 30,
      questions: 20,
      marks: 25,
      date: "Submit by 07 Jan ‘23",
      homeWorkStatus: "Retake",
    },
  ],
  // CHAPTER DETAILS TOPICS CONSTANTS
  TOPIC_TITLE: "Let's explore other topics to learn more about numbers",
  VIDEO: "Video",
  TOPIC_PATTERN: "Pattern",
  // SORT CONSTANTS
  DATE_ASC: "Oldest First",
  DATE_DSC: "Most Recent",

  // My Work constants
  MYWORK_DETAILS_BREADCRUMB_DATA: [
    {
      label: "Home",
      // link: "/home",
      selected: false,
    },
    {
      label: "My Work",
      link: "/my-work",
      selected: true,
    },
  ],
  PENDING: "Pending",
  INREVIEW: "In review",
  SKIPPED: "Skipped",
  COMPLETED: "Completed",
  EXAMPLE_QUESTIONS: "EXAMPLE QUESTIONS",
  PATTERN_DETAILS_ID: "PATTERN_DETAILS_ID",
  //Pattern Video Overlay
  VIEW_DOCUMENT: "View Document",
  VIEW_PATTERN_MAP: "View Pattern Map",
  VIEW_PATTERN_DETAILS: "View Pattern Details",
  EXAMPLES: "Examples",
  // DESCRIPTION: "Description",
  CLOSE: "Close",
  START_SETUP: "Start Setup",
  CONTINUE_SETUP: "Continue Setup",
  BACK_TO_PATTERN: "Back To Pattern",
  //my assignments module
  MYASSIGNMENTS_DETAILS_BREADCRUMB_DATA: [
    {
      label: "Home",
      // link: "/home",
      selected: false,
    },
    {
      label: "My Assignments",
      link: "/assignments",
      selected: true,
    },
  ],
  All: "All",
  ALL_ASSIGNMNETS_TITLE: "Here are all your assigned assignments",
  // My Work constants
  CHAPTERS: "Chapters",
  MYSTATS_DETAILS_BREADCRUMB_DATA: [
    {
      label: "Home",
      // link: "/home",
      selected: false,
    },
    {
      label: "My Stats",
      link: "/my-stats",
      selected: true,
    },
  ],

  // my stats constants
  myStatsHeadingText:
    "Here is your progress on all the patterns required for your grade",
  myStatsChapterHeadingTest:
    "Here is your progress on all the chapters required for your grade",
  masteryIsText: "Your Mastery is",
  masteryIsTextStats: "Your Mastery",
  classMastery: "Class Mastery is",
  FocusText:
    "Here are the Patterns you can focus on to help your students progress",
  questionsAttempted: "Questions Attempted",
  aiHelpUsed: "Times AI Help used",
  mastery: "Mastery",
  competency: "Competency",
  myPatternsQuestionsText:
    "Here are all the questions for this pattern and your progress on them",
  myChapterQuestionText:
    "Here are all the questions for this chapter and your progress on them",
  timesAttempted: "times attempted",
  timesSolved: "times solved",
  viewDiagram: "View Diagram",
  covered: "Covered",
  timeTaken: "Time taken",
  timeGiven: "Time Given",
  subTopicProgress: "Sub Topic Progress",
  NO_DATA_FOUND: "No Data found",
  // class performance chart values
  classPerformanceData: {
    labels: [
      "Test 1.1",
      "Test 1.2",
      "Test 1.3",
      "Test 1.4",
      "Test 1.5",
      "Test 1.6",
      "Test 1.7",
      "Test 1.8",
      "Test 1.9",
      "Test 2.1",
      "Test 2.2",
      "Test 2.3",
      "Test 2.4",
      "Test 2.5",
      "Test 2.6",
      "Test 2.7",
      "Test 2.8",
      "Test 2.9",
    ],
    xData: [
      5, 7, 8.2, 9.5, 4, 7.8, 8.6, 9.8, 3.7, 3, 9, 7.2, 6.5, 7, 4.8, 6.6, 8.8,
      5.7,
    ],
    lineData: [
      6.5, 10, 4, 8.2, 6.5, 3.8, 5.7, 9, 2, 4.5, 5, 10, 5.2, 6.5, 3.8, 6.7, 8,
      2,
    ],
    xTitle: "Tests",
    yTitle: "Average Score",
  },

  studentPerformanceData: {
    labels: ["Nov'22", "Dec'22", "Jan'23", "Feb'23", "Apr'23"],
    xData: [],
    lineData: [5, 3, 4, 2, 8],
    xTitle: "Month",
    yTitle: "Average Score",
  },
  //Topic Video Page
  HERE_ARE_SOME_PATTERNS: "Here are some patterns used in this topic",
  ASK_A_QUESTION: "Ask a question",
  SHOW_ONLY_MY_QUESTIONS: "Show only my questions",
  REFERENCE_PPT_ACCOMPANYING_VIDEO: "Reference ppt accompanying video",
  SOLVE_MCQ: "Solve MCQs",
  ASSIGNMENT: "Exercise",
  MINDMAP: "Mind-map",
  SOLVE_THIS_ASSIGNMENT_TO_INCREASE_YOUR_MASTERY:
    "Solve this assignment to increase yor mastery",
  TEST_YOUR_TOPIC_UNDERSTANDING: "Test your topic understanding",
  UPVOTE: "Upvote",
  VIDEO_REPORT_DESCRIPTION_360:
    "You are great at solving Commutative pattern problems. But you struggle to solve 50% of problems of Commutative + Addition pattern combination. Click here to take a quick assignment that will help you solve more problems of this pattern combination.",
  // Test Start Overlay
  INTERMEDIATE: "Intermediate",
  PATTERNS_COVERED: "Pattern Covered",
  ASSIGNMENT_INSTRUCTIONS: [
    // "Please upload descriptive answers in PDF format.",
    "Maximum file upload size is 20 MB.",
    // "Use the CamScanner app to scan the answer sheet and convert to PDF.",
    // "Use PDF compressors to compress your answer sheet.",
    "Write page numbers on each sheet.",
    "Upload images in the same sequence as the solution.",
    "Refer to below document for answering format.",
  ],
  INSTRUCTIONS: "Instructions",
  HISTORY: "History",
  NO: "No.",
  DATE: "Date",
  TIME_SPENT: "Time Spent",
  MCQ_INSTRUCTIONS: [
    "Read and understand the instructions and time limit for the exam.",
    "Skim through all the questions to get a sense of what you will be answering.",
    "Read each question carefully and eliminate obviously wrong answers.",
    "Choose the best answer from the remaining options and make educated guesses if unsure.",
    "Keep track of time and don't spend too much time on one question.",
    "Double-check your answers before moving to the next question",
    "Each question has limited time. It will be automatically submitted once the time is up.",
  ],
  DIVISIONS_DATA: [
    {
      id: 1,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 2,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 3,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 4,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 5,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 6,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 7,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 8,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
    {
      id: 9,
      divisionClass: "08",
      division: "Division A",
      noOfStudents: "42",
      pendingReview: [
        {
          icon: testIcon,
          value: 30,
          text: "Tests",
        },
        {
          icon: homeWorkIcon,
          value: 20,
          text: "Homeworks",
        },
        {
          icon: queriesIcon,
          value: 10,
          text: "Queries",
        },
      ],
    },
  ],
  LEVELS: "Levels",
  //class details constants
  STUDENTS: "Students",
  OVERALL: "Overall",

  CLASS_DETAILS_BREADCRUMB_DATA: [
    {
      label: "My Class",
      link: "/mentor/my-class",
      selected: false,
    },
    {
      label: "Class Details",
      link: "",
      selected: true,
    },
  ],
  CLASS_STUDENTS_DATA: [
    {
      name: "Arnav Sahay",
      rollNumber: "001",
      totalTopics: 80,
      topicsCompleted: 25,
      testsCompleted: 20,
      totalAssignmnets: 120,
      assignmentsCompleted: 20,
      averageScore: 65,
      photo: null,
    },
    {
      name: "Amay Gupta",
      rollNumber: "002",
      totalTopics: 50,
      topicsCompleted: 20,
      testsCompleted: 30,
      totalAssignmnets: 180,
      assignmentsCompleted: 90,
      averageScore: 75,
      photo: null,
    },
    {
      name: "Aarna Sriram",
      rollNumber: "003",
      totalTopics: 80,
      topicsCompleted: 25,
      testsCompleted: 20,
      totalAssignmnets: 120,
      assignmentsCompleted: 20,
      averageScore: 65,
      photo: null,
    },
    {
      name: "Arnav Sahay",
      rollNumber: "004",
      totalTopics: 80,
      topicsCompleted: 25,
      testsCompleted: 20,
      totalAssignmnets: 120,
      assignmentsCompleted: 20,
      averageScore: 65,
      photo: null,
    },
    {
      name: "Amay Gupta",
      rollNumber: "005",
      totalTopics: 50,
      topicsCompleted: 20,
      testsCompleted: 30,
      totalAssignmnets: 180,
      assignmentsCompleted: 90,
      averageScore: 75,
      photo: null,
    },
    {
      name: "Aarna Sriram",
      rollNumber: "006",
      totalTopics: 80,
      topicsCompleted: 25,
      testsCompleted: 20,
      totalAssignmnets: 120,
      assignmentsCompleted: 20,
      averageScore: 65,
      photo: null,
    },
  ],
  CLASS_STUDENTS_TITLE: " Here is the list of all the students in this class",
  TOPICS_COMPLETED: "Topics Completed",
  TESTS_COMPLETED: "Tests Completed",
  ASSIGNMENTS_COMPLETED: "Exercises Completed",
  AVERAGE_SCORE: "Average Score",
  CLASS_ASSIGNMENTS_DATA: [
    {
      index: "Assignment 1.1",
      name: "Application of large number in real life",
      status: "Pending",
      studentName: "005 Arnav Sangha",
      questions: 15,
      submittedOn: "03 Mar'23",
      completePercentage: "",
      totalScore: "",
      scoreGot: "",
    },
    {
      index: "Assignment 1.2",
      name: "Application of large number in real life",
      status: "Pending",
      studentName: "005 Arnav Sangha",
      questions: 15,
      submittedOn: "23 Mar'23",
      completePercentage: "60",
      totalScore: "",
      scoreGot: "",
    },
    {
      index: "Assignment 1.3",
      name: "Application of large number in real life",
      status: "Completed",
      studentName: "005 Arnav Sangha",
      questions: 15,
      submittedOn: "15 Mar'23",
      completePercentage: "",
      totalScore: "30",
      scoreGot: "20",
    },
    {
      index: "Assignment 1.4",
      name: "Application of large number in real life",
      status: "Pending",
      studentName: "005 Arnav Sangha",
      questions: 15,
      submittedOn: "07 Mar'23",
      completePercentage: "",
      totalScore: "",
      scoreGot: "",
    },
    {
      index: "Assignment 1.5",
      name: "Application of large number in real life",
      status: "Pending",
      studentName: "005 Arnav Sangha",
      questions: 15,
      submittedOn: "11 Mar'23",
      completePercentage: "60",
      totalScore: "",
      scoreGot: "",
    },
    {
      index: "Assignment 2.1",
      name: "Application of large number in real life",
      status: "Completed",
      studentName: "005 Arnav Sangha",
      questions: 15,
      submittedOn: "31 Mar'23",
      completePercentage: "",
      totalScore: "30",
      scoreGot: "20",
    },
  ],
  CLASS_ASSIGNMENT_TITLE:
    "Here are the Exercises submitted by students for review",
  ASSIGNMENT_QUESTION_LIST:
    "Here are the submitted questions from students to review",
  SUBMITTED_ON: "Submitted On",
  REVIEW_NOW: "Review Now",
  // Snackbar
  ERROR: "error",
  SUCCESS: "success",
  INVALID_CREDENTIALS: "Invalid Credentials",
  UPDATED_ON: "Updated on",

  CREATE_NEW_TEST: "CREATE NEW TEST",
  RECENT_SUBMISSIONS: "Recent Submissions",
  PATTERNS_TO_FOCUS: "Patterns to focus on for improvement",
  TEST_PERFORMANCE: "Test Performance",
  STUDY_PROGRESS: "Study Progress",
  STUDENT_DETAILS_ASSIGNMENT_TITLE:
    "Here is all the assignments submitted by your students for review",

  // return saveAPi
  NOSTUDENT_AVAILABLE: "No Student available",
  NOSTUDENT_DEC: "Hey! There are no students who are currently on",

  STUDENT_DETAILS_MOCK: {
    studentName: "Smriti Pradhan",
    rollNumber: "001",
    emailId: "smritipradhan@divami.com",
    phoneNumber: "123456789",
    levelReached: 4,
    topicsCompleted: 4,
    assignmentCompleted: 14,
    testCompleted: 18,
    averageScore: 65,
    totalStudyTime: 120,
    photo: null,
  },
  // STUDENT ACTIVITY
  ROWHEADS: ["Roll No.", "Level", "Student's Name", "Activity"],
  ROWDATA: [
    {
      rollNo: "001",
      level: "L2",
      studentName: "Arnav Sangha",
      activity: "Missed 04/04 Tests",
      photo: null,
    },
    {
      rollNo: "010",
      level: "L1",
      studentName: "Karan Desai",
      activity: "Missed 03/04 Tests",
      photo: null,
    },
    {
      rollNo: "028",
      level: "L1",
      studentName: "Raj Sharma",
      activity: "Missed 03/04 Tests",
      photo: null,
    },
    {
      rollNo: "014",
      level: "L1",
      studentName: "Junaid Md.",
      activity: "Inactive for last 30 days",
      photo: null,
    },
    {
      rollNo: "018",
      level: "L2",
      studentName: "Kaira oberoi",
      activity: "04 Homework Pending",
      photo: null,
    },
  ],
  STUDENTS_YOU_NEED_TO_FOCUS_ON: "Students you need to focus on",
  GRAPH_TIME_FILTER_OPTIONS: [
    "Last 7 days",
    "Last 15 days",
    "Last 30 days",
    "Last 3 months",
    "Last 6 months",
    "Last 1 year",
  ],
  FILTER_OPTIONS_ANALYTICS: [
    "Last 30 days",
    "Last 3 months",
    "Last 6 months",
    "Last 1 year",
  ],
  LEARN: "learn",
  STATS: "stats",
  MYCLASS: "myClass",
  STUDENT_DETAILS: "studentDetails",

  studyPref: [
    {
      question: "What is your level in class 8?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 1,
    },
    {
      question: "What is your level in class 7?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 2,
    },
    {
      question: "How good are you in problem solving?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 3,
    },
    {
      question: "How good are you in your current class of chapter 1?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 4,
    },
    {
      question: "What is your level in class 8?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 5,
    },
    {
      question: "What is your level in class 7?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 6,
    },
    {
      question: "How good are you in problem solving?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 7,
    },
    {
      question: "How good are you in your current class of chapter 1?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 8,
    },
    {
      question: "How good are you in problem solving?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 9,
    },
    {
      question: "How good are you in your current class of chapter 1?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 10,
    },
    {
      question: "How good are you in your current class of chapter 1?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 11,
    },
    {
      question: "How good are you in your current class of chapter 1?",
      answer: "0",
      type: "range",
      minValue: 0,
      maxValue: 0,
      currentValue: 0,
      id: 12,
    },
  ],

  CONTINUE: "Continue",
  NEXT: "Next",
  FINISH: "Finish",

  CHAPTER_STATS_BREADCRUMB_DATA: [
    {
      label: "Home",
      // link: "/home",
      selected: false,
    },
    {
      label: "My Stats",
      link: "/my-stats",
      selected: false,
    },
    {
      label: "Chapter Stats",
      link: "",
      selected: true,
    },
  ],
};
// Result Popup

//Assignemnt Flow

//GET ASSIGNMENT LIST
export const ASSIGNMENT_QUESTION_LIST = {
  assignment_id: 1,
  topic_id: 1,
  assignment_name:
    "Assignment 1.1  |  Application of large number in real life",
  assignment_progress: "50%",
  total_number_of_questions: 10,
  question: [
    {
      question_id: 1,
      question_text: "Question 1",
      question_type: "MCQ || descriptive",
      time_taken: "200", // in sec
      question_status: "Wrong || Complete ||  InReview || Pending",
      question_score: "10",
      question_scored: "5",
    },
  ],
};

//POST SAVE ASSIGNMENT_LIST
export const SAVE_ASSIGNMENT_LIST = {
  question_id: 1,
  selected_answer_id: 2345,
  selected_answer_text: "Answer 1",
  timeTaken: 36, // in sec
};
// return saveAPi

//solve Assignment Page
export const SOLVE_ASSIGNMENT_PAGE_CONSTANTS = {
  NEXT: "Next",
  PREVIOUS: "Previous",
  SUBMIT: "Submit",
  SKIP_QUESTION: "Skip question",
  RETERY: "Retry",
  SAVEANDNEXT: "Save and Next",
  SAVE:"SAVE"
};
// Teachers Module Study Progress Chapter Analytics Graph Data
export const MOCK_STUDENT_PROGRESS_ANALYTICS_FOR_CHAPTER = {
  status: "success",
  data: [
    {
      chapter_id: 186,
      learning_stage: 67,
      competency: 87,
      chapter_name: "Real Numbers",
    },
    {
      chapter_id: 104,
      learning_stage: 89,
      competency: 78,
      chapter_name: "Polynomials",
    },
    {
      chapter_id: 105,
      learning_stage: 12,
      competency: 13,
      chapter_name: "Pair of Linear Equations in Two Variables",
    },
    {
      chapter_id: 106,
      learning_stage: 90,
      competency: 67,
      chapter_name: "Quadratic Equations",
    },
    {
      chapter_id: 107,
      learning_stage: 12,
      competency: 89,
      chapter_name: "Arithmetic Progressions",
    },
    {
      chapter_id: 108,
      learning_stage: 23,
      competency: 11,
      chapter_name: "Triangles",
    },
    {
      chapter_id: 109,
      learning_stage: 24,
      competency: 10,
      chapter_name: "Coordinate Geometry",
    },
    {
      chapter_id: 110,
      learning_stage: 54,
      competency: 89,
      chapter_name: "Introduction to Trigonometry",
    },
    {
      chapter_id: 111,
      learning_stage: 56,
      competency: 67,
      chapter_name: "Some Applications of Trigonometry",
    },
    {
      chapter_id: 187,
      learning_stage: 78,
      competency: 56,
      chapter_name: "Circles",
    },
    {
      chapter_id: 113,
      learning_stage: 98,
      competency: 89,
      chapter_name: "Constructions",
    },
    {
      chapter_id: 114,
      learning_stage: 98,
      competency: 12,
      chapter_name: "Areas Related to Circles",
    },
    {
      chapter_id: 115,
      learning_stage: 23,
      competency: 87,
      chapter_name: "Surface Areas and Volumes",
    },
    {
      chapter_id: 116,
      learning_stage: 78,
      competency: 67,
      chapter_name: "Statistics",
    },
    {
      chapter_id: 117,
      learning_stage: 8,
      competency: 53,
      chapter_name: "Probability",
    },
  ],
};

export const MOCK_STUDENT_PROGRESS_ANALYTICS_FOR_TOPICS = {
  status: "success",
  data: [
    {
      learning_stage: 40,
      competency: 100,
      topic_id: 805,
      topic_name: "Euclid's Division Lemma",
    },
    {
      learning_stage: 80,
      competency: 20,
      topic_id: 806,
      topic_name: "The Fundamental Theorem of Arithmetic",
    },
    {
      learning_stage: 56,
      competency: 200,
      topic_id: 808,
      topic_name: "Revisiting Rational numbers and their Decimal expansions",
    },
    {
      learning_stage: 34,
      competency: 140,
      topic_id: 811,
      topic_name: "Exponential and Logarithims (SSC)",
    },
  ],
};

export const DUMMY_DATA_QUESTIONS = [
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.1",
  },
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.2",
  },
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.3",
  },
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.4",
  },
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.5",
  },
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.6",
  },
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.7",
  },
  {
    question: "From the given figure, find Tan P - Cot P. ",
    pattens_included: ["Prime factorization"],
    AI_help_used_count: [],
    Attempted_count: 0,
    Capability: 0,
    id: 7233,
    level: 1,
    question_id: 8,
    question_img: null,
    solved_count: 0,
    subtopic_id: 806,
    time_taken: 0,
    timegiven: 180,
    topic_id: 186,
    topicNumber: "1.8",
  },
];

export const LOADER_TAGS = {
  DESC_QUES_DATA: "fetchAssignmentDescriptiveQuestionsData",
};

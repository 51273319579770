import React, { useState, useEffect, useContext } from "react";
import { constants } from "../../../constants/constants";
import styles from "./assignmentsList.module.scss";
import FilterBar from "../../../common/filterBar/filterBar";

import { CustomPagination } from "../../../common/pagination/customPagination";
import SearchBar from "../../../common/searchBar/searchBar";
import useSorting from "../../../utils/useSorting";
import useSortingByDate from "../../../utils/useSortingByDate";
import StatusContext from "../../../context/loaderContext";
import HomeworkCard from "../../../common/cards/homeworkCard";
import { useLocation, useParams } from "react-router-dom";

interface IHomeworkList {
  assignmentsData: object[];
  detailedViewRef: any;
  istabbedPage?: boolean;
}
const HomeworkList = ({
  assignmentsData,
  detailedViewRef,
  istabbedPage,
}: IHomeworkList) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);
  const { chapterId } = useParams();
  const homeworkIdFromNotification: any = useLocation();

  const dataMassaging = (data: any) => {
    const updatedData = data.map((item: any) => {
      return {
        id: item.chapter.chapterid,
        homeworkTitle: item.chapter.chaptername,
        name: item.chapter.chaptername,
        numberOfQuestion: item.question_count,
        dueDate: item.date,
        status: item.status_of_homework?.status,
        topicId: item.chapter.chapterid,
        progress: item.status_of_homework?.percentage,
        homeworkId: item?.homework_id,
      };
    });

    if (chapterId && istabbedPage) {
      return updatedData.filter((item: any) => chapterId == item.id);
    }
    return updatedData;
  };
  const [searchText, setSearchText] = useState("");
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    assignmentsData,
    selectedStatus,
    searchText,
    "studentsHomework",
    dataMassaging,
    10, // range
    dropdownArray
  );

  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  return (
    <div className={styles.allAssignmentsMainContainer}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>
          {istabbedPage
            ? "Here are all the homeoworks related to this chapter"
            : "Homework"}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div className={styles.separator}></div>
      <div className={styles.sortAndFilterContainer}>
        <div className={styles.filterMainContainer}>
          <div className={styles.statusContainer}>
            <FilterBar
              statuses={["All", "pending", "incomplete", "completed"]}
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses)
              }
              showSorting={false}
              innerDropdownNeeded={true}
            />
          </div>
        </div>
      </div>
      <div className={styles.homeworkItemsContainer}>
        {sortedByDateArr.length > 0 &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, ind: number) => (
              <HomeworkCard
                data={item}
                status={item.status}
                fromNotificationId={homeworkIdFromNotification.search.replace(
                  "?homeworkId=",
                  ""
                )}
              />
            ))}
        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(HomeworkList);

import React, { useEffect, useState } from "react";
import styles from "./solveAssignmentResultOption.module.scss";
import { QuestionAndOptionsElement } from "../../../../../../../common/questionsAndOptions/questionAndOptions";
import selectedSubmit from "../../../../../../../assets/images/selectedSubmit.svg";
import disabled from "../../../../../../../assets/images/notSelectable.svg";
import correctSubmit from "../../../../../../../assets/images/correctSubmit.svg";
import wrongSubmit from "../../../../../../../assets/images/wrongSubmit.svg";
import unselected from "../../../../../../../assets/images/unChecked.svg";
import { DescriptiveReviewQuestion } from "../reviewOption/descriptiveReviewQuestion/descriptiveReviewQuestion";
import { BasicModal } from "../../../../../../../common/modalWrapper/modalWrapper";
interface IAssignmentOptions {
  question: any;
  activeQuestionIndex: any;
  optionType: any;
}

export const SolveAssignmentResultOption = (props: IAssignmentOptions) => {
  const { question, activeQuestionIndex, optionType } = props;
  const [questionData, setQuestionData] = useState<any>(question);
  //! No Need of this useEffect only for development
  //TODO:  API Issue
  useEffect(() => {
    setQuestionData((questionData: any) =>
      questionData.map((item: any, index: any) => {
        if (index === activeQuestionIndex) {
          return {
            ...item,
            selectedAnswer: item.corct_ans,
          };
        }
        return item;
      })
    );
  }, [question]);

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {optionType === "option" ? (
        <div className={styles.reviewOptionContainer}>
          {question[activeQuestionIndex].Answers.map(
            (item: any, index: any) => {
              let isMarkedCorrect =
                question[activeQuestionIndex].code == item.id ||
                (question[activeQuestionIndex].selectedAnswer != "" &&
                  item.ans != "" &&
                  question[activeQuestionIndex].selectedAnswer === item.ans);

              return (
                <div
                  className={
                    item.ansimage === ""
                      ? styles.containerOld
                      : styles.containerNew
                  }
                  key={index}
                >
                  <div
                    key={index}
                   className={`${styles.optionInside} 
                                ${
                                  isMarkedCorrect
                                    ? question[activeQuestionIndex].isCorrect
                                      ? styles.examSubmitCorrectAnswer
                                      : styles.examSubmitWrongAnswer
                                    : styles.optionInside2
                                }`}
                  >
                    <div className={styles.containerOption}>
                      <div className={styles.optionInsideUnSelect}>
                        <div className={styles.optionIcon}>
                          {
                            <img
                              className={styles.radioButtonStyle}
                              src={disabled}
                              alt="selected"
                            />
                          }
                        </div>
                        <div>
                          <label className={styles.labelText}>
                            <QuestionAndOptionsElement metaData={item.ans} />
                          </label>
                        </div>
                      </div>
                      {/* Right and Wrong Icon In result options  */}
                      <div className={styles.containerSelectImage}>
                        {question[activeQuestionIndex].selectedAnswer && (
                          <>
                            {question[activeQuestionIndex].selectedAnswer ===
                              item.ans && (
                              <img
                                className={styles.correctImageStyle}
                                src={
                                  question[activeQuestionIndex].isCorrect
                                    ? correctSubmit
                                    : wrongSubmit
                                }
                                alt="selected"
                              />
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    {item.ansimage ? (
                      <div className={styles.containerPopUp}>
                        <div className={styles.optionImageOnHoverContainer}>
                          <div className={styles.optionImageContainer}>
                            <img
                              src={item.ansimage}
                              alt="rectangle"
                              onClick={() => {
                                setOpenModal(true);
                              }}
                            />
                            {openModal ? (
                              <BasicModal
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                question={
                                  question[activeQuestionIndex].Answers[index]
                                }
                                index={index}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            }
          )}
        </div>
      ) : (
        <DescriptiveReviewQuestion
          question={question}
          activeQuestionIndex={activeQuestionIndex}
          type={"result"}
        />
      )}{" "}
    </>
  );
};

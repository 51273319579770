import React, { useEffect, useState } from "react";
import styles from "./testCard.module.scss";
import CompletedIcon from "../../assets/images/completedIcon.svg";
import SkippedIcon from "../../assets/images/skippedWorkIcon.svg";
import ReviewIcon from "../../assets/images/reviewWorkIcon.svg";
import PendingIcon from "../../assets/images/pendingWorkIcon.svg";
import DueTodayIcon from "../../assets/images/dueTodayIcon.svg";
import DueOnIcon from "../../assets/images/dueOnIcon.svg";
import TimerIcon from "../../assets/images/timerIcon.svg";
import QuestionIcon from "../../assets/images/questionCountIcon.svg";
import TickIcon from "../../assets/images/totalMarksIcon.svg";
import Mascot from "../../assets/images/mascotVSign.svg";
import CalenderIcon from "../../assets/images/resultDateIcon.svg";
import StarIcon from "../../assets/images/starIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import { SolveAssignmentQuestionListViewAll } from "../../modules/learn/pages/solveAssignmentPage/solveAssignmentMetaData/solveAssignmentQuestionPopUp/questionListViewAll/solveAssignmentQuestionListViewAll";

interface ITestCard {
  status: "incomplete" | "skipped" | "review" | "complete" | string;
  data: any;
}

const getCardIcon = (status: any) => {
  if (status == "incomplete") return PendingIcon;
  if (status == "skipped") return SkippedIcon;
  if (status == "review") return ReviewIcon;
  if (status == "complete") return CompletedIcon;
};

const TestCard = ({ status, data }: ITestCard) => {
  const [showQuestionListPopUp, setShowQuestionListPopUp] = useState(false);
  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const [acticveQuestionIndex, setActicveQuestionIndex] = useState(0);
  useEffect(() => {
    setActicveQuestionIndex(questionData.activeQuestionIndex);
  }, [questionData.activeQuestionIndex]);

  return (
    <>
      <div className={styles.testCardContainer}>
        <div className={styles.testCard}>
          <div className={styles.testCardIcon}>
            <img src={getCardIcon(status)} alt="" />
            <span>{status}</span>
          </div>
          <div className={styles.testCardDetails}>
            <p>{data.topic.chapter_name || "1. Polynomials Basics"}</p>
            <div className={styles.testCardDetailItems}>
              {status != "complete" && (
                <span>
                  <img src={TimerIcon} alt="" />
                  {data.test_time + " Mins" || "30 Min"}
                </span>
              )}
              <span>
                <img src={QuestionIcon} alt="" />
                {data.question_count || 12} Questions
              </span>
              {status != "complete" && (
                <span>
                  <img src={TickIcon} alt="" />
                  24 Marks
                </span>
              )}
              <span>
                <img
                  src={status == "incomplete" ? DueTodayIcon : DueOnIcon}
                  alt=""
                />
                {/* if status is pending and due date is today show Due today at time
              else show Submit by (date) */}
                {/* {status == "incomplete" && "Due today 6pm"}

                {status == "review" && "Submitted on 12 Jul ‘23"}
                {status == "skipped" && "Submit by 10 Jul ‘23"}
                {status == "complete" && "Submitted on 12 Jul ‘23"} */}
                {data.submit_date && data.submit_date}
              </span>
            </div>
          </div>
          {status == "complete" && (
            <>
              <div className={styles.completedRemark}>
                <span>You have done better than 87% of your classmates.</span>
                <img src={Mascot} alt="" />
              </div>
              <button className={styles.retakeButton}>Retake</button>
            </>
          )}
          <div className={styles.testCardAction}>
            {status == "incomplete" && (
              <span className={styles.testCardActionRemark}>
                <img src={CalenderIcon} alt="" /> Result Date 24 Jul ‘23
              </span>
            )}
            {status == "complete" && (
              <span className={styles.testCardActionRemark}>
                <img src={StarIcon} alt="" /> 21/24 scored
              </span>
            )}
            <button
              onClick={() => {
                //   dispatch({ type: "SET_ASSIGNMENT_LEVEL", payload: 1 });
                //   setShowAssignmentListPopUp(true);
                setShowQuestionListPopUp(true);
              }}
            >
              {status == "incomplete" && "SOLVE NOW"}

              {status == "review" && "VIEW"}
              {status == "skipped" && "VIEW"}
              {status == "complete" && "VIEW RESULT"}
            </button>
          </div>
        </div>
        {status == "skipped" && (
          <div className={styles.testCardFootNote}>
            <p>
              <img src={DueTodayIcon} alt="" />
              You missed this Test. Please contact your faculty to retake
            </p>
          </div>
        )}
      </div>
      {
        showQuestionListPopUp && (
          <SolveAssignmentQuestionListViewAll
            title={"Test"}
            closePopUp={() => setShowQuestionListPopUp(!showQuestionListPopUp)}
            activeQuestionIndex={acticveQuestionIndex}
            setActiveQuestionIndex={setActicveQuestionIndex}
            assignmentLevel={1}
            topicIdProp={data.testpaper_id}
            fromAssignmentPage={true}
            isFromTestPage={true}
          />
        ) //topicIdProp={item.id}
      }
    </>
  );
};

export default TestCard;

import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector } from "react-redux";
import { makeAxiosRequest } from "aie_common";
import styles from "./myAssignments.module.scss";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import StatusContext from "../../context/loaderContext";
import { HTTPMethods } from "../../constants/api_endpoints";
import HomeworkList from "./assignmentsList/homeworkList";

const MyHomework = ({ istabbedPage = false }: any) => {
  const detailedViewRef = useRef(null);
  const [assignmentsData, setAssignmentsData] = useState<object[]>([]);
  const courseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );
  const loaderStatus: any = useContext(StatusContext);

  const fetchAssignmentsList = async () => {
    loaderStatus.setState({ show: true });

    const payload = {
      url: `studenthomework/?course_id=${courseId}`,
      data: {},

      method: HTTPMethods.GET,
      headers: {},
    };
    try {
      const response: any = await makeAxiosRequest(payload);

      if (response && response.status === 200) {
        const assignmentsDataRes = response?.data?.data;

        setAssignmentsData(assignmentsDataRes);
        loaderStatus.setState({ show: false });
      }
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAssignmentsList();
  }, []);

  return (
    <div
      className={`${styles.myAssignmentsMainContainer} ${
        istabbedPage ? styles.allAssignmentsMainContainerTabbed : ""
      }`}
      ref={detailedViewRef}
    >
      {!istabbedPage && (
        <Breadcrumb
          breadcrumbDetails={[
            {
              label: "Home",
              link: "/home",
              selected: false,
            },
            {
              label: "Practice",
              link: "/practice/homeworks",
              selected: false,
            },
            {
              label: "Homework",
              link: "/practice/homeworks",
              selected: true,
            },
          ]}
        />
      )}
      <HomeworkList
        detailedViewRef={detailedViewRef}
        assignmentsData={assignmentsData}
        istabbedPage={istabbedPage}
      />
    </div>
  );
};

export default React.memo(MyHomework);

import React, { useState, useEffect, useContext } from "react";

import styles from "./assignmentsList.module.scss";
import { constants } from "../../constants/constants";
import FilterBar from "../../common/filterBar/filterBar";
import useSorting from "../../utils/useSorting";
import StatusContext from "../../context/loaderContext";
import useSortingByDate from "../../utils/useSortingByDate";
import { CustomPagination } from "../../common/pagination/customPagination";
import SearchBar from "../../common/searchBar/searchBar";
import TestCard from "../../common/cards/testCard";
import { useParams } from "react-router-dom";

interface IAssignmentsList {
  assignmentsData: object[];
  detailedViewRef: any;
  istabbedPage?: boolean;
}
const TestList = ({
  assignmentsData,
  detailedViewRef,
  istabbedPage,
}: IAssignmentsList) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const loaderStatus: any = useContext(StatusContext);

  const { chapterId } = useParams();

  const dataMassaging = (data: any) => {
    const newArr = data.map((item: any) => {
      return {
        ...item,
        name: item.topic.chapter_name,
        status: item.status_of_test.status,
      };
    });
    if (chapterId && istabbedPage) {
      return newArr.filter((item: any) => chapterId == item.topic.chapter_id);
    }

    return newArr;
  };
  const [searchText, setSearchText] = useState("");
  const [dropdownArray, setDropdownArray] = React.useState<any>([]);
  const {
    dropdownSelectedValueWithId,
    setDropdownSelectedValueWithId,
    filteredTopics,
    page,
    paginationStart,
    paginationEnd,
    range,
    handlePaginationChange,
  } = useSorting(
    assignmentsData,
    selectedStatus,
    searchText,
    "studentsTest", // search filter key
    dataMassaging,
    5, // range
    dropdownArray
  );

  const { sortedByDateArr, handleSorting } = useSortingByDate(filteredTopics);

  useEffect(() => {
    handleSorting("desc");
  }, [filteredTopics]);

  return (
    <div className={`${styles.allAssignmentsMainContainer}`}>
      <div className={styles.assignmnetsTitleSearchbarContainer}>
        <div className={styles.assignmentHeaderTitle}>
          {istabbedPage
            ? "Here are all the tests related to this chapter"
            : "My Tests"}
        </div>
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
      </div>

      <div className={styles.separator}></div>
      <div className={styles.sortAndFilterContainer}>
        <div className={styles.filterMainContainer}>
          <div className={styles.statusContainer}>
            <FilterBar
              statuses={["All", "incomplete", "review", "skipped", "complete"]}
              selectedStatus={(selectedStatuses: string[]) =>
                setSelectedStatus(selectedStatuses)
              }
              showSorting={false}
              innerDropdownNeeded={true}
            />
          </div>
        </div>
      </div>
      <div className={styles.testItemsContainer}>
        {sortedByDateArr.length > 0 &&
          sortedByDateArr
            ?.slice(paginationStart, paginationEnd)
            .map((item: any, ind: number) => {
              return (
                <TestCard
                  data={item}
                  status={item.status}
                  key={`item_${item?.id}_${ind}`}
                />
              );
            })}
        {filteredTopics.length === 0 && loaderStatus.show === false && (
          <div className={styles.noResultFound}>{constants.NO_DATA_FOUND}</div>
        )}
      </div>

      {filteredTopics?.length > 0 && (
        <CustomPagination
          detailedViewRef={detailedViewRef}
          page={page}
          handlePaginationChage={handlePaginationChange}
          count={Math.ceil(filteredTopics?.length / range)}
        />
      )}
    </div>
  );
};

export default React.memo(TestList);

import React, { useContext, useEffect, useState } from "react";
import styles from "./solveAssignmentNavigation.module.scss";
import { SOLVE_ASSIGNMENT_PAGE_CONSTANTS } from "../../../../../constants/constants";
import StatusContext from "../../../../../context/loaderContext";
import { useDispatch, useSelector } from "react-redux";
import ApiEdpoints, {
  HTTPMethods,
} from "../../../../../constants/api_endpoints";
import { makeAxiosRequest } from "aie_common";
import { convertToFormData } from "../../../../../utils/createFormData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Dialog } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Mascot from "../../../../../assets/images/reportCardMascot.svg";
import correctSubmit from "../../../../../assets/images/correctSubmit.svg";
import wrongSubmit from "../../../../../assets/images/wrongSubmit.svg";
import unselected from "../../../../../assets/images/unChecked.svg";
import inReview from "../../../../../assets/images/pendingIcon.svg";
import { routePaths } from "../../../../../constants/routes";
import dayjs from "dayjs";

interface SolveAssignmentNavigationProps {
  activeQuestionIndex?: any;
  setActiveQuestionIndex?: any;
  question?: any;
  setQuestion?: any;
  saveQuestion?: any;
  activeAssignmentType?: any;
  activeScreen?: any;
  setActiveScreen?: any;
  setOptionClicked?: any;
  fromExercisePage?: boolean;
  type?: string;
}

export const SolveAssignmentNavigation = (
  props: SolveAssignmentNavigationProps
) => {
  const {
    activeQuestionIndex,
    setActiveQuestionIndex,
    question,
    setQuestion,
    saveQuestion,
    activeAssignmentType,
    activeScreen,
    setActiveScreen,
    setOptionClicked,
    fromExercisePage,
    type,
  } = props;
  const loaderStatus: any = useContext(StatusContext);
  const { topicId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [saveData, setSaveData] = useState<any>();
  const [exerciseSubmitedSuccesfully, setExerciseSubmitedSuccesfully] =
    useState<any>(false);
  const [confirmSubmit, setConfirmSubmit] = useState<any>(false);

  const questionData = useSelector(
    (state: any) => state.solveAssignmentReducer
  );
  const courseId = useSelector(
    (state: any) => state.userReducer?.registeredCourseId
  );

  const [skipQuestion, setSkipQuestion] = useState(false);
  const saveAPI = async (next: any = false, skip: any = false) => {
    let payload = {
      url: ApiEdpoints.AllTESTCASES,
      data: {},
      method: HTTPMethods.POST,
      headers: {},
    };
    if (type == "test" || type == "homework") {
      payload.url = "testpapervalidation";
    }

    let formData = new FormData();
    if (
      questionData.assigmentLevel > 1 &&
      !question[activeQuestionIndex].descriptive
    ) {
      const dataObject = {
        id: question[activeQuestionIndex].Questionid,
        code: skipQuestion
          ? ""
          : question[activeQuestionIndex].code
          ? question[activeQuestionIndex].code
          : null,
        answer: skipQuestion
          ? ""
          : question[activeQuestionIndex].selectedAnswer
          ? question[activeQuestionIndex].selectedAnswer
          : null,
        time:
          (question[activeQuestionIndex].totalTime &&
            question[activeQuestionIndex].totalTime) ||
          0,
        course: courseId,
        previous: false,
        descriptive: false,
        level: questionData.assigmentLevel,
      };
      formData = convertToFormData(dataObject);
    } else if (!question[activeQuestionIndex].descriptive) {
      const dataObject = {
        id: question[activeQuestionIndex].Questionid,
        code: skipQuestion
          ? ""
          : question[activeQuestionIndex].code
          ? question[activeQuestionIndex].code
          : null,
        answer: skipQuestion
          ? ""
          : question[activeQuestionIndex].selectedAnswer
          ? question[activeQuestionIndex].selectedAnswer
          : null,
        time:
          (question[activeQuestionIndex].totalTime &&
            question[activeQuestionIndex].totalTime) ||
          0,
        course: courseId,
        previous: false,
        descriptive: false,
      };
      formData = convertToFormData(dataObject);
    } else if (
      questionData.assigmentLevel > 1 &&
      question[activeQuestionIndex].descriptive
    ) {
      const dataObject = {
        id: question[activeQuestionIndex].Questionid,
        code: skip ? null : 0,
        answer: skip ? null : 0,
        time:
          (question[activeQuestionIndex].totalTime &&
            question[activeQuestionIndex].totalTime) ||
          0,
        course: courseId,
        previous: false,
        descriptive: true,
        student_comments: question[activeQuestionIndex].student_comment
          ? question[activeQuestionIndex].student_comment
          : "",
        level: questionData.assigmentLevel,
      };
      formData = convertToFormData(dataObject);
      question[activeQuestionIndex].uploadedImages.forEach((element: any) => {
        formData.append("ans_image", element);
      });
    } else {
      const dataObject = {
        id: question[activeQuestionIndex].Questionid,
        code: skip ? null : 0,
        answer: skip ? null : 0,
        time:
          (question[activeQuestionIndex].totalTime &&
            question[activeQuestionIndex].totalTime) ||
          0,
        course: courseId,
        previous: false,
        descriptive: true,
        student_comments: question[activeQuestionIndex].student_comment
          ? question[activeQuestionIndex].student_comment
          : "",
      };
      formData = convertToFormData(dataObject);
      question[activeQuestionIndex].uploadedImages.forEach((element: any) => {
        formData.append("ans_image", element);
      });
    }
    payload = {
      url: ApiEdpoints.AllTESTCASES,
      data: formData,
      method: HTTPMethods.POST,
      headers: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header to multipart/form-data
      },
    };
    if (type == "test" || type == "homework") {
      payload.url = "testpapervalidation/";
    }
    try {
      const res: any = await makeAxiosRequest(payload);
      const newData = question.map((item: any, index: any) => {
        if (index === activeQuestionIndex) {
          return {
            ...item,
            visited:
              res.data.data === "skip"
                ? ""
                : res.data.data === "In_Review" || res.data.data === "Review"
                ? "InReview"
                : res.data.data
                ? "Completed"
                : "Wrong",
            isCorrect:
              res.data.data === "skip"
                ? false
                : res.data.data === "In_Review" || res.data.data === "Review"
                ? false
                : res.data.data,
            isAnswered: res.data.data === "skip" ? false : true,
            mcqResult: res.data.data === "skip" ? false : true,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      setQuestion(newData);
      dispatch({ type: "UPDATE_QUESTION", payload: newData });
      loaderStatus.setState({ show: false });
      setSkipQuestion(false);
      if (next && activeQuestionIndex !== question.length - 1) {
        handleClickNext();
      }
      return res;
    } catch (error) {
      loaderStatus.setState({ show: false });
      console.log(error);
      setSkipQuestion(false);
    }
  };

  const handleClickNext = async () => {
    if (activeQuestionIndex !== question.length - 1) {
      dispatch({
        type: "UPDATE_QUESTION_INDEX",
        payload: activeQuestionIndex + 1,
      });
      dispatch({ type: "UPDATE_QUESTION", payload: question });
    }

    if (activeScreen == "exerciseReport") {
      return;
    }
 
    let payload = {
      url: `GetAssignment/${topicId}/${activeQuestionIndex}/?key=homework&homework_id=${topicId}&qid=${question[activeQuestionIndex].Questionid}`,
      data: {},
      method: HTTPMethods.GET,
    };
    if (type === "test") {
      // Adjust the URL for test type
      payload.url = `testpaperquestions/${topicId}/${activeQuestionIndex}`;
      // Modify the URL if needed based on the level or other parameters for the test type
    }
    try {
      const res: any = await makeAxiosRequest(payload);
    } catch (error) {
      console.log(error);
    }

    setActiveScreen(
      question[activeQuestionIndex]?.isAnswered ? "result" : "selection"
    );
  };
  const handleClickPrevious = () => {
    if (activeQuestionIndex !== 0) {
      dispatch({
        type: "UPDATE_QUESTION_INDEX",
        payload: activeQuestionIndex - 1,
      });
      dispatch({ type: "UPDATE_QUESTION", payload: question });
      setActiveScreen(
        question[activeQuestionIndex]?.isAnswered ? "result" : "selection"
      );
    }
  };
  const handleClickSkip = () => {
    setSkipQuestion(true);
    saveAPI(false, true);
    setOptionClicked(true);
    loaderStatus.setState({ show: true });
    setTimeout(() => {
      if (activeQuestionIndex !== question.length - 1) {
        dispatch({
          type: "UPDATE_QUESTION_INDEX",
          payload: activeQuestionIndex + 1,
        });
        dispatch({ type: "UPDATE_QUESTION", payload: question });
      }
      setActiveScreen(
        question[activeQuestionIndex]?.isAnswered ? "result" : "selection"
      );

      loaderStatus.setState({ show: false });
    }, 1500);
  };

  const [reportData, setReportData] = useState<any>(null);

  const getExerciseResults = async () => {
    const payload = {
      url: `assignment_question_list/?topic_id=${topicId}`,
      data: {},
      method: HTTPMethods.GET,
      headers: {},
    };
 
      if (questionData?.assigmentLevel > 1) {
        payload.url = `assignment_question_list/?chapter_id=${topicId}&level=${questionData.assigmentLevel}`;
      }
     else if (type === "homework") {
      // Adjust the URL for homework type
      payload.url = `homework_all_questions/?homework_id=${topicId}`;
    } else if (type === "test") {
      // Adjust the URL for test type
      payload.url = `test_all_questions/?testpaper_id=${topicId}`;
      // Modify the URL if needed based on the level or other parameters for the test type
    }
    try {
      const res: any = await makeAxiosRequest(payload);
      console.log('API Response:', res);
      setReportData(res.data.data);
      setConfirmSubmit(false);
      setExerciseSubmitedSuccesfully(true);
    } catch (error) {
      console.log(error);
    }
  };
  const renderConfirmationDialogText = () => {
    if (type === "homework" || type === "test") {
      const answeredCount =reportData?.attempted;
      const notAnsweredCount = reportData?.not_attempted;
      // const inReviewCount = reportData?.total_in_review;
  
      return (
          <div className={styles.reportCardSummary}>
                  <p>Are you sure you want to submit?</p>
                  <hr></hr>
                  <p>
                  {reportData?.total_questions -
                    reportData?.not_attempted}{" "}
                  out {reportData?.total_questions} question attempted
                </p>
                  <div className={styles.homeworkpopup}>
                    <div className={styles.homeworkimg}>
                       <img src={correctSubmit} alt="" className={styles.image} />
                    </div>
                    <div className={styles.homeworktextvalue}>
                       <span className={styles.answeredCount}>{answeredCount}</span>
                        <span>Answered</span>
                     </div>
                  </div>

                  <div className={styles.homeworkpopup}>
                    <div className={styles.homeworkimg}>
                        <img src={wrongSubmit} alt="" className={styles.image} />
                    </div>
                    <div className={styles.homeworktextvalue}>
                       <span className={styles.notAnsweredCount}>{notAnsweredCount}</span>
                      <span>Not Answered</span>
                    </div>
                  </div>

                  {/* <div className={styles.homeworkpopup}>
                     <div className={styles.homeworkimg}>
                       <img src={inReview} alt="" className={styles.image} />
                      </div>
                    <div className={styles.homeworktextvalue}>
                        <span className={styles.inReviewCount}>{inReviewCount}</span>
                       <span>In Review</span>
                     </div>
                  </div> */}

                </div>
      );
    }
  
    // Default text for non-homework types
    return <p>Are you sure you want to submit?</p>;
  };

  const handleClickSubmit = (next: any = false) => {
    setOptionClicked(true);
    setTimeout(() => {
      saveAPI(next);
    }, 100);
    setTimeout(() => {
      question[activeQuestionIndex].descriptive &&
        setQuestion((prevQuestion: any) => {
          return prevQuestion.map((question: any, index: number) => {
            if (index === activeQuestionIndex) {
              const img = question.uploadedImages.map((image: any) => {
                return image.preview;
              });
              return {
                ...question,
                solutions: img,
              };
            }
            return question;
          });
        });
      setActiveScreen("result");
    }, 1000);
  };

  const handleClickRetery = () => {
    setActiveScreen("selection");
    setQuestion((prevQuestion: any) => {
      return prevQuestion.map((question: any, index: any) => {
        if (index === activeQuestionIndex) {
          return {
            ...question,
            selectedAnswer: "",
            code: "",
            totalTime: 0,
            // isCorrect: false, //!when api call
            uploadedImages: [],
            visited: "",
            isAnswered: false,
            open: false,
            mcqResult: false,
          };
        }
        return question;
      });
    });
  };

  function formatTime(seconds: any) {
    if (seconds < 60) {
      return `${seconds} sec${seconds !== 1 ? "(s)" : ""}`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (remainingSeconds === 0) {
        return `${minutes} min${minutes !== 1 ? "(s)" : ""}`;
      } else {
        return `${minutes} min${
          minutes !== 1 ? "s" : ""
        } and ${remainingSeconds} sec${remainingSeconds !== 1 ? "(s)" : ""}`;
      }
    } else {
      const hours = Math.floor(seconds / 3600);
      const remainingMinutes = Math.floor((seconds % 3600) / 60);
      let formattedTime = `${hours} hour${hours !== 1 ? "(s)" : ""}`;
      if (remainingMinutes > 0) {
        formattedTime += ` and ${remainingMinutes} min${
          remainingMinutes !== 1 ? "(s)" : ""
        }`;
      }
      return formattedTime;
    }
  }

  return (
    <>
      <div className={styles.solveAssignmentNavigation}>
        {activeScreen === "selection" && (
          <div>
            <button
              onClick={handleClickSkip}
              className={styles.cancelButton}
              disabled={question.length - 1 === activeQuestionIndex}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SKIP_QUESTION}
            </button>

            <button
              className={styles.cancelButton}
              onClick={handleClickPrevious}
              disabled={activeQuestionIndex === 0}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.PREVIOUS}
            </button>
            <button
              // disabled={question.length - 1 === activeQuestionIndex}
              className={styles.cancelButton}
              onClick={() => handleClickSubmit(true)}
            >
              {question.length - 1 !== activeQuestionIndex
                ? SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SAVEANDNEXT
                : SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SAVE}
            </button>
            <button
              // disabled={question.length - 1 === activeQuestionIndex}
              className={styles.cancelButton}
              onClick={() => {
                setConfirmSubmit(true);
              }}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SUBMIT}
            </button>
          </div>
        )}
        {activeScreen === "result" && (
          <>
            <button
              className={styles.cancelButton}
              onClick={handleClickPrevious}
              disabled={activeQuestionIndex === 0}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.PREVIOUS}
            </button>
            <button
              disabled={question.length - 1 === activeQuestionIndex}
              className={styles.cancelButton}
              onClick={handleClickNext}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.NEXT}
            </button>
            <button
              //disabled={question.length - 1 === activeQuestionIndex}
              className={styles.cancelButton}
              onClick={handleClickRetery}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.RETERY}
            </button>
            <button
              // disabled={question.length - 1 === activeQuestionIndex}
              className={styles.cancelButton}
              onClick={() => {
                setConfirmSubmit(true);
              }}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SUBMIT}
            </button>
          </>
        )}
        {activeScreen === "exerciseReport" && (
          <>
            <button
              className={styles.cancelButton}
              onClick={handleClickPrevious}
              disabled={activeQuestionIndex === 0}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.PREVIOUS}
            </button>
            <button
              disabled={question.length - 1 === activeQuestionIndex}
              className={styles.primaryButton}
              onClick={handleClickNext}
            >
              {SOLVE_ASSIGNMENT_PAGE_CONSTANTS.NEXT}
            </button>
          </>
        )}

        {/* {question[activeQuestionIndex]?.visited !== "InReview" &&
          activeScreen !== "exerciseReport" && (
            <button
              disabled={
                //   question[activeQuestionIndex]?.descriptive &&
                //   question[activeQuestionIndex]?.uploadedImages?.length === 0
                fromExercisePage &&
                question.length - 1 === activeQuestionIndex &&
                activeScreen !== "selection"
              }
              className={
                question[activeQuestionIndex]?.uploadedImages?.length !== 0 ||
                question[activeQuestionIndex]?.selectedAnswer !== ""
                  ? styles.primaryButton
                  : styles.cancelButton
              }
              onClick={
                activeScreen === "selection"
                  ? handleClickSubmit
                  : handleClickRetery
              }
            >
              {fromExercisePage ? (
                <>
                  {activeScreen === "selection"
                    ? SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SUBMIT
                    : SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SUBMIT}
                </>
              ) : (
                <>
                  {activeScreen === "selection"
                    ? SOLVE_ASSIGNMENT_PAGE_CONSTANTS.SUBMIT
                    : SOLVE_ASSIGNMENT_PAGE_CONSTANTS.RETERY}
                </>
              )}
            </button>
          )} */}
      </div>
      <Dialog
        open={exerciseSubmitedSuccesfully}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={styles.createModalWrap}>
          <div className={styles.createSelection}>
            <img src={Mascot} alt="" />
            <div>
              <div className={styles.successfullyCreated}>
                <div className={styles.reportCardAttempt}>
                  <p>
                    <span>You Scored </span>
                    <strong>{reportData?.total_marks_scored}/</strong>
                    {reportData?.total_marks_alloted}
                  </p>
                  <div>
                    <p>
                      <span>
                        {dayjs(reportData?.last_attempted_date).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                      <span> Attempt Date</span>
                    </p>
                    <p>
                      <span>{formatTime(reportData?.total_time_taken)}</span>
                      <span>Time Taken</span>
                    </p>
                  </div>
                </div>
                <p>
                  {reportData?.total_questions -
                    reportData?.total_not_attempted}{" "}
                  out {reportData?.total_questions} question attempted
                </p>
                <div className={styles.reportCardSummary}>
                  <p>
                    <img src={correctSubmit} alt="" />
                    {reportData?.total_correct}
                    <span> Correct</span>
                  </p>
                  <p>
                    <img src={wrongSubmit} alt="" />
                    {reportData?.total_wrong}
                    <span> Incorrect</span>
                  </p>
                  <p>
                    <img src={inReview} alt="" />
                    {reportData?.total_in_review}
                    <span> In Review</span>
                  </p>
                  <p>
                    <img src={unselected} alt="" />
                    {reportData?.total_not_attempted}
                    <span> N/A</span>
                  </p>
                </div>
                <div className={styles.questionDivisionGraph}>
                  <div
                    className={styles.correct}
                    style={{
                      width: `${
                        (reportData?.total_correct /
                          reportData?.total_questions) *
                        100
                      }%`,
                    }}
                  ></div>

                  <div
                    className={styles.incorrect}
                    style={{
                      width: `${
                        (reportData?.total_correct /
                          reportData?.total_questions) *
                          100 +
                        (reportData?.total_wrong /
                          reportData?.total_questions) *
                          100
                      }%`,
                    }}
                  ></div>
                  <div
                    className={styles.inReview}
                    style={{
                      width: `${
                        (reportData?.total_correct /
                          reportData?.total_questions) *
                          100 +
                        (reportData?.total_wrong /
                          reportData?.total_questions) *
                          100 +
                        (reportData?.total_in_review /
                          reportData?.total_questions) *
                          100
                      }%`,
                    }}
                  ></div>
                  <div
                    className={styles.notAnswered}
                    style={{
                      width: `${100}%`,
                    }}
                  ></div>
                </div>
              </div>
              <div className={styles.createSelectionDateButton}>
                <button
                  className={styles.link}
                  onClick={() => {
                    dispatch({ type: "ENABLE_RETAKE", payload: true });
                    dispatch({ type: "UPDATE_QUESTION_INDEX", payload: 0 });

                    let path = routePaths.solveExercise;
                    location.state = {
                      retake: true,
                    };
                    navigate(path.replace(":topicId", `${topicId}`), {
                      state: { timeStamp: Date.now() },
                    });
                    setExerciseSubmitedSuccesfully(false);
                  }}
                >
                  Retake Exercise
                </button>

                {(reportData?.next_chapter_id || reportData?.next_topic_id) && (
                  <>
                    <button
                      className={styles.primary}
                      onClick={() => {
                        if (reportData?.next_chapter_id) {
                          navigate(
                            routePaths.chapterDetails.replace(
                              ":chapterId",
                              reportData?.next_chapter_id
                            )
                          );
                          return;
                        }
                        if (reportData?.next_topic_id) {
                          navigate(
                            routePaths.learnVideoPlayer.replace(
                              ":topicId",
                              reportData.next_topic_id
                            )
                          );
                        }
                      }}
                    >
                      {reportData?.next_chapter_id && "Go to next Chapter"}
                      {reportData?.next_topic_id && "Go to next Topic"}
                    </button>
                  </>
                )}
                <button
                  className={styles.primary}
                  onClick={() => {
                    dispatch({
                      type: "UPDATE_QUESTION_INDEX",
                      payload: 0,
                    });
                    let path: any = routePaths.exercisesReport;

                    if (questionData?.assigmentLevel > 1) {
                      path = path
                        .replace(":topicId", `${topicId}`)
                        .replace(":level", `${questionData.assigmentLevel}`);
                    } else {
                      path = path
                        .replace(":topicId", `${topicId}`)
                        .replace(":level", `1`);
                    }
                    navigate(path);
                    setExerciseSubmitedSuccesfully(false);
                  }}
                >
                  Report Card
                </button>
              </div>
            </div>
          </div>

          <button
            onClick={() => {
              setExerciseSubmitedSuccesfully(false);
            }}
          >
            <CloseRounded />
          </button>
        </div>
      </Dialog>
      
      <Dialog
        open={confirmSubmit}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        <div className={styles.confirmSubmitModal}>
        <h3>{renderConfirmationDialogText()}</h3>
          <div>
            <button className={styles.primary} onClick={getExerciseResults}>
              Yes
            </button>
            <button
              className={styles.secondary}
              onClick={() => {
                setConfirmSubmit(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Footer from "./common/footer/footer";
import SideNavBar from "./common/sideNavBar/sideNavBar";
import ApiEdpoints from "./constants/api_endpoints";
import { constants } from "./constants/constants";
import {
  componentRoutes,
  IRouteComponents,
  routePaths,
} from "./constants/routes";
import StatusContext from "./context/loaderContext";
import {
  fetchNotificationCount,
  fetchPersonalInfo,
} from "./utils/personalInfo";
import { useSelector } from "react-redux";

const AuthenticatedApp = () => {
  const navigate = useNavigate();
  const userType = localStorage.getItem("USERTYPE");
  const [type, setType] = useState<any>(userType);
  const [data, setData] = useState(
    type === "Student"
      ? constants.SIDE_NAV_DATA
      : constants.SIDE_NAV_DATA_TEACHERS
  );
  const loaderStatus: any = useContext(StatusContext);

  useEffect(() => {
    fetchPersonalInfo(loaderStatus, ApiEdpoints.STUDENT_INFO);
    fetchNotificationCount(loaderStatus, ApiEdpoints.NOTIFICATIONSCOUNT);
    // type === "Student"
    // ? navigate(routePaths.learn)
    // : navigate(routePaths.mentorMyClass);
  }, []);

  const notificationCount = useSelector(
    (state: any) => state.userReducer.notificationCount
  );

  useEffect(() => {
    if (notificationCount) {
      const updateData = data.map((item) => {
        if (item.title === "Notifications") {
          return { ...item, count: notificationCount };
        } else {
          return item;
        }
      });
      setData(updateData);
    }
  }, [notificationCount]);

  return (
    <div className="mainWrapper">
      <div className="sideNavBarContainer">
        <SideNavBar data={data} setData={setData} handleExpand={() => {}} />
      </div>
      <div className="pageContent">
        <Routes>
          {componentRoutes.map((eachRoute: IRouteComponents) => (
            <Route
              path={eachRoute.path}
              element={eachRoute.element}
              key={eachRoute.path.split("/").join("-")}
            />
          ))}
          {/* /  route will navigate to student automatically */}
          {/* <Route path="*" element={userType === 'mentor' ? <Navigate to={routePaths.mentor}/> : <Navigate to={routePaths.dashboard} />} /> */}
          <Route
            path="*"
            element={
              <Navigate
                to={
                  type === "Student"
                    ? routePaths.learn
                    : routePaths.mentorMyClass
                }
              />
            }
          />
        </Routes>
        <Footer />
      </div>
    </div>
  );
};
export default AuthenticatedApp;

import React, { useState } from "react";
import ClassPerformanceChart from "../../../../../common/classPerformanceChart/classPerformanceChart";
import { constants } from "../../../../../constants/constants";
import StudentActivity from "../../components/studentActivity/studentActivity";
import StudentPatterns from "../../components/studentPatterns/studentPatterns";
import styles from "./classDetailsOverview.module.scss";
import ClassDetailsHeadingCard from "../../components/classDetailsHeadingCard/classDetailsHeadingCard";
import { useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../../../../constants/routes";
import { useSelector } from "react-redux";

const ClassDetailsOverview = (props: any) => {
  const navigate = useNavigate();

  const paths = window.location?.pathname.split("/");
  const params = useParams();
  const id = params.classId ?? "";

  const viewAnalytics = () => {
    navigate(routePaths.classAnalytics.replace(":classId", id.toString()));
  };
  const filterData = constants.GRAPH_TIME_FILTER_OPTIONS?.map(
    (x: any, i: number) => ({ name: x, id: i + 1 })
  );
  const [chartDatesOptions, setChartDatesOptions] = useState(filterData);
  const [filteredValues, updatedFilteredValues] = useState([]);
  const username = useSelector((state: any) => state.userReducer.username);

  return (
    <div className={styles.overviewMainContainer}>
      <div className={styles.overviewMainHeading}>
        {constants.MENTOR_GREETINGS_CD.replace("user", username)}
      </div>
      <div>
        <ClassDetailsHeadingCard id={id} />
      </div>
      <div>
        <StudentActivity setSelectedIndexData={props.setSelectedIndexData} />
      </div>
      <div>
        <StudentPatterns />
      </div>
      <div className={styles.graphContainer}>
        <div className={styles.overviewClassPerformanceHeading}>
          <span>Class Performance</span>
          <span>
            <div onClick={viewAnalytics} className={styles.viewAll}>
              View Analytics
            </div>
          </span>
        </div>
        <ClassPerformanceChart
          data={constants.classPerformanceData}
          isLinePlot={false}
          showDropDownFilter={true}
          chartFilterWidth={"140px"}
          chartFilterOptions={chartDatesOptions}
          chartFilterDefaultLabel={"Select date"}
          filteredSelectedValues={updatedFilteredValues}
          comparisonsInX_Axis={9}
        />
      </div>
    </div>
  );
};

export default ClassDetailsOverview;
